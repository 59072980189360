<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:staff_type="{ item }">
        <div class="text-capitalize">
          {{ item.staff_type.replace(/_/g, " ") }}
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.staff_type"
            dense
            clearable
            :items="staffTypeOptions"
            item-value="id"
            item-text="name"
            :label="$t('staff_type')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-driving-staff
            v-if="isCreate"
            :filter-options="options"
          />

          <edit-driving-staff
            v-if="(isEdit || isView) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateDrivingStaff: () => import("./dialogs/CreateDrivingStaff.vue"),
    EditDrivingStaff: () => import("./dialogs/EditDrivingStaff.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      // module_name: "csmsChargeStations", //vuex module name which define in Store Index
      permissions: {
        view: "driving-staff.view",
        create: "driving-staff.create",
        edit: "driving-staff.edit",
        delete: "driving-staff.delete",
      },
      //
      filters: {},
      subFilters: {},
      //
      customFilters: {},
      //

      staffTypeOptions: [
        {
          id: "chief_crew",
          name: "Crew",
        },
        {
          id: "driver",
          name: "Driver",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "drivingStaff/getLoading",
      meta: "drivingStaff/getMeta",
      list: "drivingStaff/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        // {
        //   text: this.$t("tenant_name"),
        //   align: "left",
        //   sortable: false,
        //   value: "tenant_name",
        //   showAdmin: true,
        // },
        // ...(this.authUser.user_nature == "fleet"
        //   ? [
        //       {
        //         text: this.$t("manufacturer"),
        //         align: "left",
        //         sortable: false,
        //         value: "tenant_name",
        //       },
        //     ]
        //   : []),
        {
          text: this.$t("email"),
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("staff_type"),
          align: "left",
          sortable: false,
          value: "staff_type",
        },
        // {
        //   text: this.$t("customer_type"),
        //   align: "left",
        //   sortable: false,
        //   value: "customer_type",
        // },
        {
          text: this.$t("phone_number"),
          align: "left",
          sortable: false,
          value: "phone_number",
        },
        // {
        //   text: this.$t("status"),
        //   align: "left",
        //   sortable: false,
        //   value: "status",
        // },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["search"];
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "driving_staff",
      filters: { ...this.options, ...this.customFilters },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options, ...this.customFilters };
      params.status = params.status
        ? params.status == "active"
          ? "1"
          : "0"
        : null;
      await this.$store.dispatch("drivingStaff/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("drivingStaff/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
